import { Device } from '@twilio/voice-sdk';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { DefaultUserStatuses } from '../constants/UserStatusesEnum';
import { CallDeviceStatusEnum, CallActivityDirectionEnum } from '../constants/CallStatusEnum';
import { ContactTypeEnum } from '../constants/ContactTypeEnum';
import { CallBarState } from '../constants/CallBarState';
import { CallMedium } from '../Enums/ActivityEnum';

import ContactService from '../Services/ContactService';
import TwilioService from "../Services/Twilio/TwilioService";
import CallNotificationService from '../Services/Call/CallNotification';
import StreamProcessManagerService from '../Services/StreamProcessManagerService';
import SaleforceService from './SaleforceService';
import CallActivityService from './Call/CallActivity';

import { getTwilioData } from '../slices/twilio';
import { callStatusChange } from '../slices/callState';
import { clearCallingContact } from '../slices/callingContact';
import { updateUserStatus, addLastCallRecord } from '../slices/auth';
import { callError, clearResumingCallDetails } from '../slices/callState';
import { cleanupContactSearchByPhone } from '../slices/contactSearchByPhone';
import {
  updateCallBarState, setIncomingCallIdle,
  setIncomingCallTriggered, updateCurrentActiveCallRecordId,
  setCallPrimaryMetadata, getCallIncoming,
} from '../slices/callCentre';
import { store } from '../app/store';
import RealTimeClient from './RealTimeClient';

const LogTitle = 'TwilioVoiceService';
const CALLCONNECTIONMC = "rocketphone__CALLCONNECTIONMC__c";

export default class TwilioVoiceService {
  static callConnection: any = null;
  static streamProcessManager = new StreamProcessManagerService(); // The real time transcription socket map manager.
  static twilioDevice: any = null;
  static streamConnectionTimerId: any = null;

  static CallDirectionEnum = Object.freeze({
    incoming: 'INCOMING',
    outgoing: 'OUTGOING',
  });

  /**
   * This sets up the twilio device for this instance of the app.
   * @static
   * @param {string} twilioToken        The twilio token that is sent from the backend
   * @param {string} effectiveRegionId  Specifies which Twilio Data Center to use when registering, initiating calls, and receiving calls.
   * @param {string} callStateToRestore Override the call state with this value.
   *                                    Useful to restore in-call state if refresh happened in the middle of a call.
   *                                    Default: CallDeviceStatusEnum.Ready
   */

  static async setupTwilio(twilioToken: string, effectiveRegionId: string, callStateToRestore = CallDeviceStatusEnum.Ready) {
    // if there is no token, we cannot setup the device.
    if (!twilioToken) {
      // TODO: Need to Log
      return
    };

    console.log(LogTitle, 'setupTwilio', {effectiveRegionId, callStateToRestore });

    try {
      TwilioVoiceService.twilioDevice = new Device(twilioToken, {
        logLevel: 1,
        // allowIncomingWhileBusy: true,
        // @ts-ignore
        codecPreferences: ["opus", "pcmu"],
        tokenRefreshMs: 30000,
        maxCallSignalingTimeoutMs: 30000,
        appName : 'RP4S',
        appVersion: '1.0.0',
        closeProtection: true,
      });

      TwilioVoiceService.twilioDevice.on("registered", () => {
        console.log(LogTitle, 'The device is ready to receive incoming calls', TwilioVoiceService.twilioDevice.edge);
        this.readyHandler(CallDeviceStatusEnum.Ready);
      });

      TwilioVoiceService.twilioDevice.on("error", TwilioVoiceService.errorHandler);
      TwilioVoiceService.twilioDevice.on("incoming", TwilioVoiceService.incomingHandler);
      TwilioVoiceService.twilioDevice.audio.on('deviceChange', TwilioVoiceService.deviceChangeHandler);
      TwilioVoiceService.twilioDevice.on('tokenWillExpire', TwilioVoiceService.tokenWillExpireHandler);

      // // @ts-ignore
      // TwilioVoiceService.twilioDevice.audio.availableOutputDevices.forEach((device, id) => {
      //   console.info('Available device:', id, '(labeled', device.label, ')', {device});
      // });

      // store.dispatch(setupTwilioSuccess());
      
      TwilioVoiceService.twilioDevice.on('unregistered', () => {
        TwilioVoiceService.attemptReRegistration();
      });

      // Device must be registered in order to receive incoming calls
      TwilioVoiceService.twilioDevice.register();

      CallNotificationService.registerPageVisibilityListener();
      //setInterval(TwilioVoiceService.checkNetworkStatus, 5000); // Check every 5 seconds

    } catch (exception) {
      // store.dispatch(setupTwilioError(exception));
    }
  }

    // Attempt re-registration
    static attemptReRegistration = () => {
      console.log(LogTitle, 'Trying to re-register Twilio Device...', TwilioVoiceService.twilioDevice.state);
      setTimeout(() => {
        if (TwilioVoiceService.twilioDevice.state === 'unregistered') {
          try {
            TwilioVoiceService.twilioDevice.register(); // Attempt re-registration
            console.log(LogTitle, 'Re-registration attempted.');
          } catch (err) {
            console.error(LogTitle, 'Failed to re-register Twilio device:', err);
            const userId = store.getState()?.auth?.authUser?.userId;
            console.log(LogTitle, 'The device unregistered failed, try to get new token with userId: ', userId);
            store.dispatch(getTwilioData(userId));
          }
        } else {
          // Check internet availability
          console.log(LogTitle, 'Trying to re-register Twilio Device but got already registered...');
        }
      }, 4000); // Retry after 4 seconds (adjust as needed)
    }

  static destroy() {
    // We need to make sure the offline handler doesn't get triggered when destroying the device
    // TwilioDevice.off('offline', TwilioVoiceService.offlineHandler);
    // TwilioDevice.destroy();
    // store.dispatch(resetTwilio());
  }

  static safeDestroy() {
    try {
      // // We need to make sure the offline handler doesn't get triggered when destroying the device
      // TwilioDevice.off('offline', TwilioVoiceService.offlineHandler);
      // TwilioDevice.destroy();

    } catch (error) {
      console.log(LogTitle, 'Failed to destroy Twilio Device', { error });
    }

    // store.dispatch(resetTwilio());
  }

  /**
   * Initiates a connection to the caller number that is specified in the connect params.
   * @static
  */
  static async connect(user: any, tenantSettings: any, connectParams: any) {
    if (connectParams) {
      try {
        const callRecordId = connectParams?.callRecordId ? connectParams?.callRecordId :
          `${uuidv4()}__${user?.id}`;
        const callForwardChainReference = connectParams?.callForwardChainReference ? connectParams?.callForwardChainReference :
          uuidv4();
        
        // TODO: We probably shouldn't be disconnecting an incoming call. Maybe hold it?
        // if there is an incoming call disconnect it.
        if (TwilioVoiceService.callConnection) {
          // @ts-ignore
          TwilioVoiceService.callConnection.reject();
          TwilioVoiceService.callConnection = null;
        }

        // convert the object keys to pascal case.
        const params = {
          From: connectParams.fromNumber,
          FromId: connectParams.fromId,
          To: connectParams.toNumber,
          ToId: connectParams.toId,
          TenantCode: connectParams.tenantCode,
          CorrelationToken: uuidv4(),
          CallRecordId: callRecordId,
          CallForwardChainReference: callForwardChainReference,
          ContactId: connectParams.toId,
          ContactType: connectParams.contactType || ContactTypeEnum.unknown,
          ContactFirstName: connectParams.contactFirstName,
          ContactLastName: connectParams.contactLastName,
          AssignedTeamId: connectParams.assignedTeamId,
          TraversedRoutes: connectParams.traversedRoutes,

          CallEventId: connectParams.callEventId, // Call from dialler
          CampaignId: connectParams.campaignId, // Call from dialler
          CallNote: connectParams.callNote,
        };

        TwilioVoiceService.callConnection = await TwilioVoiceService.twilioDevice.connect({ params });
        TwilioVoiceService.callConnection.on("accept", TwilioVoiceService.acceptHandler);
        TwilioVoiceService.callConnection.on("disconnect", TwilioVoiceService.disconnectHandler);
        TwilioVoiceService.callConnection.on("cancel", TwilioVoiceService.disconnectHandler);
        TwilioVoiceService.callConnection.on("ringing", TwilioVoiceService.ringingHandler);

        store.dispatch(updateCallBarState(CallBarState.CALLING));
        TwilioVoiceService.updateUserAvailableStatus(callRecordId, true);
        const callingContact = store.getState()?.callingContact?.data;
        let contactRemoteId = connectParams?.contactRemoteId;

        if (!contactRemoteId && callingContact) {
          contactRemoteId = callingContact?.contactRemoteId || callingContact?.remoteId;
        }

        if (contactRemoteId) {
          SaleforceService.navigateToObject(contactRemoteId);
        } else {
          SaleforceService.callingObjectNavigation(connectParams?.toNumber, connectParams?.toId, true);
        }

        // We are sending this message to liveTranscript lwc component.
        // So we have to make sure that component has been rendered (as liveTranscript renders when we connect the call) while sending this message.
        // So giving a timeout 2sec to pass the message.
        setTimeout(() => SaleforceService.publishObject({callId: callRecordId ,type: 'Connect'}, CALLCONNECTIONMC), 2000);
      } catch (exception) {
        store.dispatch(callError(exception));
      }
    } else {
      // store.dispatch(callError('Connection params cannot be null'));
    }
  }

  // static createOutboundCallConnectParams = (user, userNumber, contact, contactNumber, callEventId = null) => ({
  static createOutboundCallConnectParams =
    (
      user: any, userNumber: any,
      contact: any, contactNumber: any, assignedTeamId: any,
      callEventId = null, campaignId = null, callRecordId = null, callForwardChainReference = null, callNote = '',
    ) => ({
      callRecordId,
      callForwardChainReference,
      fromId: user?.id,
      fromName: user?.firstName,
      fromNumber: userNumber,
      toName: ContactService.getContactFullName(contact),
      toId: contact?.contactId || contact?.id,
      tenantCode: user?.organisationDetails?.tenantCode,
      toNumber: contactNumber,
      contactRemoteId: contact?.RemoteId,
      contactFirstName: contact?.firstName,
      contactLastName: contact?.lastName,
      contactType: contact?.contactType,
      assignedTeamId,
      // Call event stuff
      callEventId,
      campaignId,
      callNote,
    })

  /**
  * Gets the active on going connection for the device.
  * @static
  */
  static getActiveConnection = () => {
    return TwilioVoiceService.callConnection || null;
  }

  /**
  * Send DTMF digits.
  * @static
  */
  static sendDigits = (digits: any) => {
    const connection = TwilioVoiceService.getActiveConnection();

    if (connection) {
      // @ts-ignore
      connection.sendDigits(digits);
      return true;
    }

    return false;
  }

  /**
  * Mute mic
  * @static
  */
  static muteMic = (muteState: boolean) => {
    const connection = TwilioVoiceService.getActiveConnection();

    if (connection) {
      // @ts-ignored
      connection.mute(muteState);
    }
  }

  /**
  * Terminates the active connection. This will trigger the disconnect event handler.
  * @static
  */
  static disconnect = () => {
    const connection = TwilioVoiceService.getActiveConnection();

    if (connection) {
      // @ts-ignored
      connection.disconnect();
    } else {
      const disconnectAll = TwilioVoiceService.twilioDevice.disconnectAll();
      
      if (!disconnectAll) {
        if (TwilioVoiceService.twilioDevice.state !== 'Ready') {
          // TODO: Need to Log
          TwilioVoiceService.twilioDevice.destroy();
          const twilioToken = store.getState()?.twilio?.data?.token;
          const effectiveRegionId = store.getState()?.twilio?.data?.regionId;
          const restoringCallState = 'Ready';
          TwilioVoiceService.setupTwilio(twilioToken, effectiveRegionId, restoringCallState);
        }
      }
    }
  }

  /**
   * Toggle recording pause. Note that pause recording won't work if recording has not started.
   *
   * @static
   * @param callRecordId
   */
  static pauseRecording(callRecordId: string, muted = true) {
    TwilioVoiceService.streamProcessManager.setStreamMuteState(callRecordId, muted);
  }

  /**
   * Create RTT socket connection and begin streaming.
   *
   * @static
   * @param callRecordId
   */
  static startRttStreaming(callRecordId: string) {
    TwilioVoiceService.streamProcessManager.startConnection(callRecordId);
  }

  // Twilio Call back handler
  static readyHandler = (callStateToRestore = CallDeviceStatusEnum.Ready) => {
    store.dispatch(callStatusChange({ status: callStateToRestore }));
  }

  /**
   * This is triggered when a connection is opened, whether initiated using .connect() or via an accepted incoming connection.
   * @static
   */
  static acceptHandler = (connection: any) => {
    console.log(LogTitle, "acceptHandler: ", { connection });

    const callDirection = TwilioVoiceService.getCallDirection(connection);
    const isIncoming = callDirection === CallActivityDirectionEnum.incoming;
    console.log(LogTitle, `Accept call direction isIncoming: ${isIncoming} call Status: ${connection.status()}`)

    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(connection);

    // Check if this is Agent 2 getting the call connection event
    if (this.isConnectedCallHotTransferAgent(connection)) {
      // In a hot transfer, the second connection (with agent) should be handled differently
      // What we are doing here is recording the call connected event
      // CallHotTransferService.hotTransferAgentConnectedSequence();
      return;
    }
    const callRecordId = callDetails?.callRecordId;

    // If this is a resuming call, we need to make sure we clear the queued call details
    if (TwilioVoiceService.wasCallOnHold(connection)) {
      const currentActiveCallRecordId = store.getState()?.callCentre?.currentActiveCallRecordId;
      if (currentActiveCallRecordId && currentActiveCallRecordId === callRecordId) {
        console.log(LogTitle, "acceptHandler: wasCallOnHold", { currentActiveCallRecordId, callRecordId });

        store.dispatch(clearResumingCallDetails());
        TwilioVoiceService.streamProcessManager.receiveCallHoldUpdate(callRecordId, false);
      }

      return;
    }
   
    if (isIncoming) {
      // Start RTTP streaming
      CallActivityService.resolveCallInProgressStatusChange({id: callRecordId });
      TwilioVoiceService.updateUserAvailableStatus(callRecordId, true);
    } else {
      TwilioVoiceService.callConnection.on("volume", TwilioVoiceService.volumeHandler);
    }

    // // Do the needful setups for the call
    store.dispatch(updateCurrentActiveCallRecordId(callDetails?.callRecordId));
    store.dispatch(setCallPrimaryMetadata(
      {
        previousCallRecordId: callDetails?.previousCallRecordId,
        callRecordId: callDetails?.callRecordId,
        contactNumber: callDetails?.contactNumber,
        customerHasJoinedConference: callDetails?.customerHasJoinedConference,
        isHotTransfer: callDetails?.isHotTransfer,
        isConference: callDetails?.isConference,
        metadata: callDetails,
      }
    ));

    const callingContact = store.getState()?.callingContact?.data;
    const callRecordDetail = {callRecordId, content:{callDirection:callDetails?.callDirection, callMedium: CallMedium.salesForce }, contactDetails: callingContact}
    store.dispatch(addLastCallRecord(callRecordDetail));
  }

  /**
     * Emitted when any device error occurs. These may be errors in your request, your token, connection errors,
     * or other application errors.
     *
     * See the Twilio Client error code reference for more information. https://www.twilio.com/docs/client/errors
     * @static
     */
  static errorHandler = async(error: any, call: any) => {
    // update the server with the current active connections
    // RealTimeClient.sendActiveConnectionIds();
    console.log(LogTitle, "errorHandler: ", { error, call })
    store.dispatch(callStatusChange({ status: CallDeviceStatusEnum.Error }));

    if (!TwilioVoiceService.callConnection && (error?.code === 20104 || error?.code === 20101)) {
      const { tenantCode, AccessToken } = store.getState()?.auth?.authUser;
      const requestBody = {
        callerId: store.getState()?.auth?.authUser?.userId
      };
  
      const result = await TwilioService.getTwilioData(tenantCode, AccessToken, requestBody);
      const twilioInfo = result.data
      const twilioToken = twilioInfo?.data?.token;
      TwilioVoiceService.twilioDevice.updateToken(twilioToken);
      console.log(LogTitle, "errorHandler : Update token, for code: ", error?.code);
    }
  };

  /**
  * This is triggered when the connection to Twilio drops or the device's token is invalid/expired.
  * @static
  */
  static offlineHandler = () => {
    // re initialize the device since its offline
    // store.dispatch(setupTwilio());
    store.dispatch(getTwilioData(store.getState()?.auth?.authUser?.userId));
  };

  /**
  * This is triggered when the connection to Twilio drops or the device's token is invalid/expired.
  * @static
  */
  static volumeHandler = (inputVolume: any, outputVolume: any) => {
    //console.log(LogTitle, "volumeHandler: ", { inputVolume, outputVolume});
  };

  /**
   * This is triggered whenever an incoming connection is made.
   * The handler function receives a Twilio.Connection object as an argument.
   * This connection will be in state pending until you call .accept() on it.
   * @static
   */
  static incomingHandler = (connection: any) => {
    store.dispatch(getCallIncoming());
    console.log(LogTitle, "incomingHandler: ", { connection })
    const currentActiveCallRecordId = store.getState()?.callCentre?.currentActiveCallRecordId;
    const incomingCallInfoData = store.getState()?.callCentre?.incomingCallInfo;

    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(connection);
    const callRecordId = callDetails?.callRecordId;
    const existingcallDetails = incomingCallInfoData?.callDetails;
    const existingCallrecordId = existingcallDetails?.callRecordId;

    if ((existingcallDetails && existingCallrecordId !== callRecordId) ||
      (TwilioVoiceService.wasCallOnHold(connection) && currentActiveCallRecordId !== callRecordId)) {
      connection.ignore();
      console.log(LogTitle, "incomingHandler: ignore", { currentActiveCallRecordId, callRecordId})

      return;
    }

    if (!!currentActiveCallRecordId && currentActiveCallRecordId !== callRecordId) {
      console.log(LogTitle, "incomingHandler: disconnect", { currentActiveCallRecordId, callRecordId})
      connection.disconnect();

      return;
    }

    TwilioVoiceService.callConnection = connection;
    TwilioVoiceService.callConnection.on("disconnect", TwilioVoiceService.disconnectHandler);
    TwilioVoiceService.callConnection.on("cancel", () => TwilioVoiceService.cancelHandler(TwilioVoiceService.callConnection));
    TwilioVoiceService.callConnection.on("reject", () => TwilioVoiceService.cancelHandler(TwilioVoiceService.callConnection));
    TwilioVoiceService.callConnection.on("accept", TwilioVoiceService.acceptHandler);

    // If this is a resuming call, we need to make sure we clear the queued call details
    if (TwilioVoiceService.wasCallOnHold(connection)) {
      if (currentActiveCallRecordId && currentActiveCallRecordId === callRecordId) {
        console.log(LogTitle, "incomingHandler: wasCallOnHold and accept()", { currentActiveCallRecordId, callRecordId })
        connection.accept();
        return;
      }
    }

    // Execute call incoming sequence
    const incomingCallType = this.getIncomingCallType(connection);
    const { firstName, lastName } = store.getState()?.auth?.user;
    const assignedTeam = store.getState()?.auth?.userTeam;
    const hasTeam = !!(assignedTeam?.id === callDetails?.assignedTeamId);
    const hasHuntGroup = !!callDetails?.traversedRoutes?.[1];
    let displayName = `${firstName} ${lastName}`;

    if (hasTeam) {
      if (hasHuntGroup) {
        displayName = `\n${callDetails?.traversedRoutes[0]?.RouteCode} (${callDetails?.traversedRoutes[0]?.RouteName}) > ${callDetails?.traversedRoutes[1]?.RouteCode} (${callDetails?.traversedRoutes[1]?.RouteName})`;
      } else {
        displayName = `${assignedTeam?.route} (${assignedTeam?.name})`;
      }
    }

    SaleforceService.getContactSearchByPhone(callDetails?.contactNumber)
      .then((result) => {
        if (TwilioVoiceService.callConnection) {
          store.dispatch(setIncomingCallTriggered({ contacts: result || [], incomingCallType, callDetails }));
          let potentialContact;
          const contactRemoteId = callDetails?.contactRemoteId;
      
          if (result?.length > 0) {
            if (contactRemoteId) {
              potentialContact = result?.find((co: any) => co?.remoteId === contactRemoteId);
            }
      
            if (!potentialContact) {
              potentialContact = result?.[0];
            }
          }
  
          CallNotificationService.showIncomingCallNotification(callDetails, potentialContact, displayName);
        }        
      })
      .catch((error) => {
        if (TwilioVoiceService.callConnection) {
          store.dispatch(setIncomingCallTriggered({ incomingCallType, callDetails }));
          CallNotificationService.showIncomingCallNotification(callDetails, null, displayName);
        }
      });

    SaleforceService.setSoftPhoneVisibleIfNot();
  }

  static ringingHandler = (hasEarlyMedia : any) => {
    console.log(LogTitle, "ringingHandler: ", { hasEarlyMedia  })
    store.dispatch(updateCallBarState(CallBarState.IN_CALL));
    TwilioVoiceService.getStreamProcessServiceConnection();
  };

  static updateUserAvailableStatus = (callRecordId: string | null, isOnCall: boolean) => {
    const tenantUserStatusesState = store.getState()?.settings?.tenantSettings?.userStatuses;
    let userStatusId = null;
    let isAvailable = true;
    
    if (isOnCall) {
      const onCallUserStatus = tenantUserStatusesState?.filter((item: any) => item?.name === DefaultUserStatuses.OnCall)?.[0];
      userStatusId = onCallUserStatus?.id;
      isAvailable = false;
    } else {
      const defaultAvailableStatus = tenantUserStatusesState?.filter((item: any) => item?.name === DefaultUserStatuses.Available)?.[0];
      userStatusId = defaultAvailableStatus?.id;
    }
    
    const userStatuses = { Available: isAvailable, UserStatusId: userStatusId, ConnectedCallId: callRecordId };
    store.dispatch(updateUserStatus(userStatuses));
  }

  /**
   * This is triggered when an incoming connection is canceled by the caller before it is accepted by the Twilio Client device.
   * @static
   */
  static cancelHandler = (connection: any) => {
    console.log(LogTitle, "cancelHandler: ", { connection })

    const currentActiveCallRecordId = store.getState()?.callCentre?.currentActiveCallRecordId;
    const cancelledCallRecordId = TwilioVoiceService.getCallDetailsFromConnection(connection)?.callRecordId;
    const incomingCallRecordId = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection)?.callRecordId;

    if (!!currentActiveCallRecordId && currentActiveCallRecordId !== cancelledCallRecordId) {
      console.log(LogTitle, "cancelHandler: prevent ", { currentActiveCallRecordId, cancelledCallRecordId });
      return;
    }

    // update the last connection.
    TwilioVoiceService.callConnection = null

    // Execute the cancel sequence
    CallNotificationService.hideIncomingCallNotification(incomingCallRecordId);
    // update the server with the current active connections
    // RealTimeClient.sendActiveConnectionIds();
    //TwilioVoiceService.updateUserAvailableStatus(null, false);
    SaleforceService.publishObject({callId: currentActiveCallRecordId, type: 'Cancel'}, CALLCONNECTIONMC);

    // We have to hide the incoming call panel
    store.dispatch(clearCallingContact());
    store.dispatch(cleanupContactSearchByPhone());
    store.dispatch(setIncomingCallIdle());
  };

  /**
   * Fired any time a Connection is closed. The handler function receives the Twilio.Connection object that was
   * just closed as an argument.
   * @static
   */
  static disconnectHandler = (connection: any) => {
    // eslint-disable-next-line
    console.log(LogTitle, "disconnectHandler: ", { connection })
    const callsOnHoldData = store.getState()?.callState?.callsOnHold;
    const currentActiveCallRecordId = store.getState()?.callCentre?.currentActiveCallRecordId;

    // If it's a held call, we don't have to do antyhing
    // Redux Store Call Hold state check
    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(connection);
    const callRecordId = callDetails?.callRecordId;

    if (currentActiveCallRecordId && callRecordId === currentActiveCallRecordId) {
      const callIsPutOnHold = callsOnHoldData[callRecordId];
      // const callsOnHold = callStateSelectors.callsOnHold(store.getState()) || {};

      if (callIsPutOnHold) {
        TwilioVoiceService.streamProcessManager.receiveCallHoldUpdate(callRecordId, true);
        return;
      }

      const callingContact = store.getState()?.callingContact?.data;
      const callRecordDetail = {callRecordId, content:{callDirection:callDetails?.callDirection, callMedium: CallMedium.salesForce }, contactDetails: callingContact}
      store.dispatch(addLastCallRecord(callRecordDetail));

      const isCallDispositionNotMandatory = store.getState()?.settings?.tenantSettings?.tenantCallSettings?.isCallDispositionNotMandatory;
      if (isCallDispositionNotMandatory) {
        store.dispatch(updateCallBarState(CallBarState.IDLE));
      } else {
        store.dispatch(updateCallBarState(CallBarState.POST_CALL));
      }

      store.dispatch(clearCallingContact());
      TwilioVoiceService.updateUserAvailableStatus(null, false);
      store.dispatch(updateCurrentActiveCallRecordId(null));
      SaleforceService.publishObject({callId: callRecordId, type: 'Disconnect'}, CALLCONNECTIONMC);
    }

    // If we get here, it's a regualr as call
    // Going with the usual thingy
    // remove closed connections
    TwilioVoiceService.cleanupConnectionData(connection);

    // After disconnecting, we need to do some stuff to make the UI take effect
    this.postDisconnectCleanup();
  }


  /**
   * After the call disconnect is finished, the resets we need to do
  */
  static postDisconnectCleanup = () => {
    if (TwilioVoiceService.streamConnectionTimerId) {
      clearInterval(TwilioVoiceService.streamConnectionTimerId);
    }
    // Reset the device status back to "Ready"
    store.dispatch(callStatusChange({ status: CallDeviceStatusEnum.Ready }));

    const isCallDispositionNotMandatory = store.getState()?.settings?.tenantSettings?.tenantCallSettings?.isCallDispositionNotMandatory;
    if (isCallDispositionNotMandatory) {
      store.dispatch(updateCallBarState(CallBarState.IDLE));
    } else {
      store.dispatch(updateCallBarState(CallBarState.POST_CALL));
    }
    // store.dispatch(updateConnectedUserInitialDetails(null));
    TwilioVoiceService.callConnection = null;
  }

  static deviceChangeHandler = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log(LogTitle, 'Navigator is not available.');
      return;
    }

    // const twilioSetupCompleted = callStateSelectors.twilioSetupCompleted(store.getState());

    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        let isAudioInputAvailable = false;
        console.log(LogTitle, 'available devices', devices);
        devices.forEach((device) => {
          if (device.kind === 'audioinput') {
            isAudioInputAvailable = true;
          }
        });
        // store.dispatch(updateCallAudioInputState(isAudioInputAvailable));

        // We destroy the twillio connection if the Mic is not connected
        // We have to dispatch setupTwillio if the Mic is connected again
        if (!isAudioInputAvailable) {
          console.log(LogTitle, 'Input device not available', devices);
          //TwilioVoiceService.safeDestroy();
        }
        //  else if (isAudioInputAvailable && !twilioSetupCompleted) {
        //    store.dispatch(setupTwilio());
        // }
        //TwilioVoiceService.twilioDevice.audio.ringtoneDevices.set('c88d223096980c7df8e647fa2e13762c744d033c430c156cd2e301ea07e88308'); // Set active device
      })
      .catch((err) => {
        console.log(LogTitle, `${err.name}: ${err.message}`, { err });
      });
  }

  static tokenWillExpireHandler = async() => {
    console.log(LogTitle, "tokenWillExpireHandler: ", TwilioVoiceService.callConnection);

    if (!TwilioVoiceService.callConnection) {
      const { tenantCode, AccessToken } = store.getState()?.auth?.authUser;
      const requestBody = {
        callerId: store.getState()?.auth?.authUser?.userId
      };
  
      const result = await TwilioService.getTwilioData(tenantCode, AccessToken, requestBody);
      const twilioInfo = result.data
      const twilioToken = twilioInfo?.data?.token;
      TwilioVoiceService.twilioDevice.updateToken(twilioToken);
      console.log(LogTitle, "tokenWillExpireHandler: Update token, state: ", TwilioVoiceService.twilioDevice.state);
    }
  }

  /**
   * Clean up connection information
  */
  static cleanupConnectionData = (connection: any) => {
    // Remove closed connection from TwilioVoiceService class and socket mapping map
    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(connection);
    const callRecordId = callDetails?.callRecordId;
    TwilioVoiceService.streamProcessManager.stopConnection(callRecordId);
  }

  // Helper Methods

  static wasCallOnHold = (connection: any) => connection.customParameters?.get('CallWasOnHold')?.toLowerCase() === 'true';

  /**
 * Says whether this is the following scenario (Agent 1 perspective)
 *  - Agent 1 hot transfers a call to Agent 2
 *  - Call connected to Agent 2
 */
  static isConnectedCallHotTransferAgent = (connection: any) => {
    // const { callRecordId } = TwilioVoiceService.getCallDetailsFromConnection(connection);

    return false;
    // return CallHotTransferService.isConnectedCallHotTransferAgent(callRecordId);
  }

  /**
   * Gets the correct CallSid (RemoteId) from the connection.
   *
   * The CallSid will change when a call is resumed after holding.
   * But we should still use the iriginal CallSid since it is the RemoteId used to create the CallRecord.
   * This function will automatically handle the conditional logic.
   *
   * @param   {any}     connection  The connection object given by Twilio SDK
   * @return  {string}              The RemoteId of the connection
   */
  static getCallRemoteId = (connection: any) => {
    const customRemoteId = connection?.customParameters?.get('CallRemoteId');
    const customRemoteIdAvailable = !!customRemoteId && customRemoteId !== 'null';
    let result = connection?.parameters.CallSid;

    if (customRemoteIdAvailable) result = customRemoteId;

    return result;
  }

  /**
   * Gets the correct Call Direction from the connection.
   *
   * The direction is always "Incoming" for a resumed call regardless of it's original direction.
   * Therefore if we use `connection.direction` on a outbound call that was resumed things will go wrong.
   *
   * @param   {any}     connection  The connection object given by Twilio SDK
   * @return  {string}              The correct CallDirection of the connection
   */
  static getCallDirection = (connection: any) => {
    const callWasOnHold = TwilioVoiceService.wasCallOnHold(connection);
    const connectionDirection = connection.direction === TwilioVoiceService.CallDirectionEnum.incoming ?
      CallActivityDirectionEnum.incoming : CallActivityDirectionEnum.outgoing;

    return callWasOnHold ? connection.customParameters.get('CallDirection') : connectionDirection;
  }

  /**
   * Get the incoming call type
   *  - If it's from a client, we have to handle it differently
   *  - If it's from a number, we need to handle in the regular way
   *
   * @param   {any}                   connection  Twilio connection object
   * @return  {IncomingCallTypeEnum}              Incoming call type enum
   */
  static getIncomingCallType = (connection: any) => {
    const from = connection.parameters.From;
    let result = 'Customer';

    // If the call is from a client, it's an agent call
    if (from?.startsWith('client:')) result = 'Agent';

    return result;
  }

  // TODO: Change this in the future
  // Agent calls should be possible without it being a hot transfer
  static isAgentCall = (connection: any) => connection?.customParameters?.get('isHotTransfer') === 'True'

  static isCallHotTransfer = (connection: any) => connection?.customParameters?.get('isHotTransfer') === 'True'

  static customStringParameterSafetyCheck = (value: any) => {
    let result = null;

    if (!!value && value !== 'null') result = value;

    return result;
  }

  static toTwilioSafeString = (id: any) => {
    let result = null;

    if (this.customStringParameterSafetyCheck(id)) {
      result = id
        .replaceAll(/-/g, '_hyphen_')
        .replaceAll(/\+/g, '_plus_')
        .replaceAll(/ /g, '_spc_')
        .replaceAll(/:/g, '_colon_');
    }

    return result;
  }

  static toRegularFromTwilioSafeId = (id: any) => {
    let result = null;

    if (this.customStringParameterSafetyCheck(id)) {
      result = id
        .replaceAll(/_hyphen_/g, '-')
        .replaceAll(/_plus_/g, '+')
        .replaceAll(/_spc_/g, ' ')
        .replaceAll(/_colon_/g, ':');
    }

    return result;
  }

  static toRegularBoolFromTwilioCustomParam = (bool: string) => bool === 'True'

  static toArrayFromTwilioCustomParam = (param: any) => {
    let result = null;

    // No need to proceed if the param is not given
    if (!param) return null;

    try {
      result = JSON.parse(param);
    } catch (error) {
      // Do nothing lol
    }

    return result;
  }
  /**
   * Get agent call details from connection object of a hot transfer call
   *
   * @param {any} connection Connection object from Twilio SDK
   */
  static getHotTransferCallDetailsFromConnection = (connection: any) => {
    const customParameters = connection?.customParameters;
    const customDataAvailable = !!this.customStringParameterSafetyCheck(customParameters?.get('callRecordId'));
    let result = null;

    if (customDataAvailable) {
      const agentId = this.toRegularFromTwilioSafeId(customParameters.get('agentId'));
      const agentName = this.toRegularFromTwilioSafeId(customParameters.get('agentName'));
      const agentTeamName = this.toRegularFromTwilioSafeId(customParameters.get('AgentTeamName'));
      const contactId = this.toRegularFromTwilioSafeId(customParameters.get('contactId')) || null;
      const contactRemoteId = connection.customParameters.get('ContactRemoteId') || null;
      const contactType = this.toRegularFromTwilioSafeId(customParameters.get('contactType')) || ContactTypeEnum.unknown;
      const firstName = this.toRegularFromTwilioSafeId(customParameters.get('contactFirstName')) || '';
      const lastName = this.toRegularFromTwilioSafeId(customParameters.get('contactLastName')) || '';
      const contactAccountId = this.toRegularFromTwilioSafeId(customParameters.get('contactAccountId')) || null;
      const contactAccountName = this.toRegularFromTwilioSafeId(customParameters.get('contactAccountName')) || null;
      const contactName = [firstName, lastName].filter(name => !!name && name !== 'undefined').join(' ');
      const contactNumber = this.toRegularFromTwilioSafeId(customParameters.get('contactNumber')) || null;
      const callRecordId = this.toRegularFromTwilioSafeId(customParameters.get('callRecordId'));
      const isHotTransfer = this.toRegularBoolFromTwilioCustomParam(customParameters.get('isHotTransfer'));
      const assignedTeamId = connection.customParameters.get('AssignedTeamId') || null;
      const traversedRoutes = this.toArrayFromTwilioCustomParam(connection.customParameters?.get('TraversedRoutes'));

      // Maybe we should have another param for this?
      const isConference = this.toRegularBoolFromTwilioCustomParam(customParameters.get('isHotTransfer'));

      // TODO: Remove hardcoded value after getting this correctly
      const customerOnHold = this.toRegularBoolFromTwilioCustomParam(customParameters.get('customerOnHold')) || true;

      const customerHasJoinedConference = this.toRegularBoolFromTwilioCustomParam(customParameters.get('contactHasJoined'));
      const previousCallRecordId = this.toRegularFromTwilioSafeId(customParameters.get('previousCallRecord'));
      const callDirection = TwilioVoiceService.getCallDirection(connection);
      const isIncoming = callDirection === CallActivityDirectionEnum.incoming;
      const callEventId = null;
      let callStartedAt = null;
      let callHeldAt = null;

      const firstCallStartTime = customParameters.get('firstCallStartTime');
      const callHoldTime = customParameters.get('callHoldTime');

      if (firstCallStartTime) callStartedAt = new Date(Number(firstCallStartTime) * 1000).toISOString();
      if (callHoldTime) callHeldAt = new Date(Number(callHoldTime) * 1000).toISOString();

      result = {
        // Agent information
        agentId, agentName, agentTeamName,
        // Contact information
        contactId, contactRemoteId, contactType, contactName, contactNumber, accountId: contactAccountId, accountName: contactAccountName,
        unknownCaller: contactType === ContactTypeEnum.unknown,
        // Call information
        remoteId: TwilioVoiceService.getCallRemoteId(connection), previousCallRecordId,
        customerHasJoinedConference, customerOnHold, traversedRoutes,
        isHotTransfer, isConference, callRecordId, callDirection, isIncoming, callEventId, callStartedAt, callHeldAt, assignedTeamId,
      };
    }

    return result;
  };

  /**
   * Get agent call details from connection object of a regular call
   *
   * @param {any} connection Connection object from Twilio SDK
   */
  static getContactCallDetailsFromConnection = (connection: any) => {
    const customParameters = connection?.customParameters;
    const customDataAvailable = !!this.customStringParameterSafetyCheck(customParameters?.get('CallRecordId'));
    let result = null;
    
    if (customDataAvailable) {
      const callRecordId = connection.customParameters.get('CallRecordId');
      const callDirection = TwilioVoiceService.getCallDirection(connection);
      const initCallDirection = connection.customParameters.get('CallDirection') ?? callDirection;
      const isIncomingInitiated = initCallDirection === CallActivityDirectionEnum.incoming;
      const isIncoming = callDirection === CallActivityDirectionEnum.incoming;
      const contactType = connection.customParameters.get('ContactType') || ContactTypeEnum.unknown;
      const contactId = connection.customParameters.get('ContactId') || null;
      const contactRemoteId = connection.customParameters.get('ContactRemoteId') || null;
      const firstName = connection.customParameters.get('ContactFirstName') || '';
      const lastName = connection.customParameters.get('ContactLastName') || '';
      const callEventId = connection.customParameters.get('CallEventId') || null;
      const campaignId = this.customStringParameterSafetyCheck(connection.customParameters?.get('CampaignId')) || null;
      const campaignName = this.customStringParameterSafetyCheck(connection.customParameters?.get('CampaignName')) || null;
      const accountId = this.customStringParameterSafetyCheck(connection.customParameters?.get('AccountId')) || null;
      const accountName = this.customStringParameterSafetyCheck(connection.customParameters?.get('AccountName')) || null;
      const contactName = [firstName, lastName].filter(name => !!name && name !== 'undefined').join(' ');
      const contactNumber = isIncomingInitiated ? connection.parameters.From : (connection.customParameters.get('To') ?? connection.customParameters.get('MobilePhone') ?? connection.customParameters.get('Phone'));
      const assignedTeamId = connection.customParameters.get('AssignedTeamId') || null;
      const callFromNumber = connection.parameters.From;
      const isMutedCall = connection?.mediaStream?.isMuted;
      const traversedRoutes = this.toArrayFromTwilioCustomParam(connection.customParameters?.get('TraversedRoutes'));
      const callForwardedByUserName = connection.customParameters?.get('CallForwardedByUserName');
      const callForwardedByUserId = connection.customParameters?.get('CallForwardedByUserId');
      const callNote = connection.customParameters?.get('CallNote');

      result = {
        callRecordId,
        unknownCaller: contactType === ContactTypeEnum.unknown,
        contactId,
        contactRemoteId,
        contactName,
        contactNumber,
        contactType,
        remoteId: TwilioVoiceService.getCallRemoteId(connection),
        callDirection,
        isIncoming,
        callEventId,
        campaignId,
        campaignName,
        accountId,
        accountName,
        assignedTeamId,
        callFromNumber,
        isMutedCall,
        traversedRoutes,
        callForwardedByUserId,
        callForwardedByUserName,
        callNote,
        previousCallRecordId: null,
        customerHasJoinedConference: false,
        isHotTransfer: false,
        isConference: false,
      };
    }

    return result;
  }

  /**
   * Gets the call details from the connection object.
   *
   * @param   {any}  connection  The connection object given by Twilio SDK
   * @return  {any}              The call details object
   */
  static getCallDetailsFromConnection = (connection: any) => {
    // const isAgentCall = this.isAgentCall(connection);
    const isHotTransferCall = this.isCallHotTransfer(connection);
    let result = null;
    if (isHotTransferCall) {
      result = this.getHotTransferCallDetailsFromConnection(connection);
    } else {
      result = this.getContactCallDetailsFromConnection(connection);
    }

    return result;
  }

  // we can use this later
  static getStreamProcessServiceConnection = () => {
    // We have to clear the streamConnectionTimerId
    if (TwilioVoiceService.streamConnectionTimerId) {
      clearInterval(TwilioVoiceService.streamConnectionTimerId);
    }

    TwilioVoiceService.streamConnectionTimerId = setInterval(() => {
      const callDetails = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection);
      const isCallSateNotInProgres = store.getState()?.callCentre?.callBarState !== CallBarState.IN_PROGRESS;

      if (isCallSateNotInProgres) {
        RealTimeClient.getCallRecordStatus(callDetails?.callRecordId);
      }
    }, 10000)
  }
}
 