import * as signalR from '@microsoft/signalr';

import { store } from '../app/store';
import { CallBarState } from '../constants/CallBarState';
import { SnackbarType } from '../constants/SnackbarType';
import { CallActivityStatusEnum } from '../Enums/CallActivityActionType';
import { CallActivityDirectionEnum } from '../constants/CallStatusEnum';
//import { DefaultUserStatuses } from '../constants/UserStatusesEnum';

import { showSnackbar } from '../slices/snackbar';
import { addCallingContact } from '../slices/callingContact';
import { deleteScheduleCall } from '../slices/scheduledCalls';
import { updateUserAvailabilityStatus } from '../slices/auth';
import { updateCallBarState, setIncomingCallIdle, updateCurrentActiveCallRecordId, setIsCurrentCallInHold } from '../slices/callCentre';
import BrowserApiService from './BrowserApi';
import SaleforceService  from '../Services/SaleforceService';
import TwilioVoiceService  from '../Services/TwilioVoiceService';
import CallActivityService from '../Services/Call/CallActivity';

const LogTitle = 'RealTimeClient';
const SignalRConnectionState = {
  Connecting: 'Connecting',
  Connected: 'Connected',
  Reconnecting: 'Reconnecting',
  Disconnected: 'Disconnected',
};

export default class RealTimeClient {
  // The signalR connection object
  static connection: signalR.HubConnection;
  static connectionHubProxy = null;

  // The twilio verification success callback, will be called when the verification is successful
  static twilioVerificationSuccessCallback = null;

  // When connection drops, we queue messages until connection is re-established
  static pendingMessagesQueue = [];


  /**
   * Creates a signalR connection to the server.
   * @param userProfile user details object.
   * @returns The connection object.
   */
  static async connect(connectionUrl: string, accessToken: string) {
    this.createHubConnection(connectionUrl, accessToken);
    // this.updateCallerIdVerificationStatus(RealTimeClient.connection);
    //this.updateCallList(RealTimeClient.connection);
    // this.updateCampaign(RealTimeClient.connection);
    // this.updateCampaignCallEvents(RealTimeClient.connection);
    this.updateUserNotifications(RealTimeClient.connection);
    // this.deleteNotifications(RealTimeClient.connection);
    this.UpdateContactInfo(RealTimeClient.connection);
    // Call events
    this.updateCallStatusChange(RealTimeClient.connection);
    this.recordingAdded(RealTimeClient.connection);
    this.transcriptionAdded(RealTimeClient.connection);
    this.bookmarkTranscriptionsAdded(RealTimeClient.connection);
    // this.nlpBookmarksAdded(RealTimeClient.connection);
    // this.updateConferenceCallStatusChange(RealTimeClient.connection);

    // Insight events
    // this.dailyUserInsightUserStatusUpdate(RealTimeClient.connection);
    // this.dailyUserInsightOutgoingCallInsightUpdate(RealTimeClient.connection);
    // this.dailyUserInsightIncommingCallInsightUpdate(RealTimeClient.connection);

    // Call Queue
    // this.updateCallQueueWithCallAuditEvent(RealTimeClient.connection);
    this.availabilityInquireEvent(RealTimeClient.connection);

    // User Status update event
    this.updateUserStatusChange(RealTimeClient.connection);
    this.updateUserDisconnectMessage(RealTimeClient.connection);
    this.RCCallTranscript(RealTimeClient.connection);

    await this.connectAsync();
    this.registerPageVisibilityHandlers();
    // If connection succeeds, let's send all pending messages
    this.sendPendingMessages();

    // Logger.info(LogTitle, 'SignalR connection established');

    // Registering this callback function to manually reconnect if the connection disconnects due to the access token being expired
    RealTimeClient.connection?.onclose(() => {
      const hubDisconnected = RealTimeClient.connection?.state === SignalRConnectionState.Disconnected;
      if (hubDisconnected) {
        this.reconnectClient();
      } 
    });

    return RealTimeClient.connection;
  }

  /**
   * Create a hub connection
   */
  static createHubConnection = (
    connectionUrl: string,
    accessToken: string,
  ) => {
    RealTimeClient.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${connectionUrl}`, { accessTokenFactory: () => accessToken })
      .withAutomaticReconnect()
      .build();
  }

  /**
   * Connect the socket
   */
  static connectAsync = () => RealTimeClient.connection.start()

  /**
   * Disconnect the socket
   */
  static disconnectAsync = () => RealTimeClient.connection.stop()

  static safeDisconnect = async () => {
    // Making sure the connection is available
    if (!RealTimeClient.connection) {
      console.log(LogTitle, 'Connection not set. Skipping real time client disconnect');
      return;
    }

    try {
      await RealTimeClient.connection.stop();
    } catch (error) {
        console.log(LogTitle, 'Failed to disconnect. Skipping', { error });
    }
  }

  /**
   * Reconnect the client
   */
  static reconnectClient = () => {
    // Basically we re-negotiate the SignalR connection
    //store.dispatch(negotiateSignalR());
  }

  /**
   * Send the device connection status to the server. If the device is busy,
   * this user will not get another incoming till the device becomes idle
   */
  static sendActiveConnectionIds = async () => {
    const { userId, tenantCode } = store.getState()?.auth?.authUser;

    let callRecordId =  null;
    const connection = TwilioVoiceService.getActiveConnection();

    if (connection) {
      const callDetails = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection);
      callRecordId = callDetails?.callRecordId;
    }

    const currentConnectionState = RealTimeClient.connection?.state;
    console.log(LogTitle, "currentConnectionState", {tenantCode, userId, callRecordId });
    
    if (currentConnectionState === 'Connected' && tenantCode && userId) {
      try {
        RealTimeClient.connection.invoke("UpdateInquery", tenantCode, userId, callRecordId);
      } catch (err: any) {
        console.error("UpdateInquery" + err.toString());
      }
    }
  }

  static setUserLogEvent = (logEvent: any) => {
    const { userId, tenantCode } = store.getState()?.auth?.authUser;
    const currentConnectionState = RealTimeClient.connection?.state;
    const eventJsonString = JSON.stringify(logEvent);

    if (currentConnectionState === 'Connected' && tenantCode && userId) {
      try {
        console.error("SetUserLogEvent sent mssg:" + eventJsonString);
        RealTimeClient.connection.invoke("SetUserLogEvent", tenantCode, userId, eventJsonString);
      } catch (err: any) {
        console.error("SetUserLogEvent Error: " + err.toString());
      }
    }
  }

  static getCallRecordStatus = (callRecordId: string) => {
    const { userId, tenantCode } = store.getState()?.auth?.authUser;
    const currentConnectionState = RealTimeClient.connection?.state;
    if (currentConnectionState === 'Connected' && tenantCode && userId) {
      try {
        RealTimeClient.connection.invoke("GetCallStatus", tenantCode, userId, callRecordId);
      } catch (err: any) {
        console.error("getCallRecordStatus" + err.toString());
      }
    }
  }

  /**
   * Handles page visibility change
   */
  static handlePageVisibilityChange = async () => {
    const hubDisconnected = RealTimeClient.connection.state === SignalRConnectionState.Disconnected;
    // @ts-ignore
    const pageBecameVisible = !document[BrowserApiService.Visibility.visibilityParams.hidden];

    if (pageBecameVisible && hubDisconnected) {
      this.reconnectClient();
      console.log(LogTitle, 'Page gained visibility. Refreshed connection');
    }
  }

  /**
   * Registering the Page Visibility change handlers
   */
  static registerPageVisibilityHandlers = () => {
    BrowserApiService.Visibility.registerListener(this.handlePageVisibilityChange);
  }

  /**
   * Safely sends SignalR messages
   */
//   static  safeEmit = async (...params) => {
//     const currentConnectionState = RealTimeClient.connection?.state;

//     switch (currentConnectionState) {
//       case SignalRConnectionState.Connecting:
//         RealTimeClient.pendingMessagesQueue.push(params);
//         this.reconnectClient();
//         break;
//       case SignalRConnectionState.Connected:
//         await RealTimeClient.connection.send(...params);
//         break;
//       case SignalRConnectionState.Reconnecting:
//         RealTimeClient.pendingMessagesQueue.push(params);
//         break;
//       case SignalRConnectionState.Disconnected:
//         console.log(LogTitle, 'Connection');
//         RealTimeClient.pendingMessagesQueue.push(params);
//         this.reconnectClient();
//         break;
//       default:
//         break;
//     }
//   }

  /**
   * Send all queued messages
   */
  static sendPendingMessages = () => {
    const currentQueue = [...RealTimeClient.pendingMessagesQueue];

    // currentQueue.forEach(params => (RealTimeClient.connection.send(...params)));

    // A workaround for race conditions
    // Shouldn't happen, but adding it anyway
    RealTimeClient.pendingMessagesQueue = RealTimeClient.pendingMessagesQueue.splice(0, currentQueue.length);
  }

//   /**
//    * Listens to the caller Id verification message sent from the server.
//    * @param connection the signalR connection object
//    */
//   static updateCallerIdVerificationStatus = (connection: signalR.HubConnection) => {
//     connection.on('CallerIdVerificationStatus', (phoneObj) => {
//       if (RealTimeClient.twilioVerificationSuccessCallback) {
//         RealTimeClient.twilioVerificationSuccessCallback(phoneObj);
//       }
//     });
//   }

/**
   * Listens to user notifications.
   * @param connection the signalR connection object
   */
static updateUserNotifications = (connection: signalR.HubConnection) => {
  connection.on('UserNotification', (notification) => {
    console.log(LogTitle, "UserNotification: ", {notification});
    // TODO: Need to Delete Schedule Call if Notification Type is DeletedScheduleCall
    if (notification?.notificationType === 'ScheduledCalls' && notification?.content?.subType === 'CancelScheduledCall') {
      store.dispatch(deleteScheduleCall(notification?.content?.callEventId));
    } else if (notification?.notificationType === 'CoachingTips' && notification?.content?.callSummary) {
      const calleeName = notification?.content?.contactName || notification?.content?.contactAccountName;
      const snackbarData = { contactName: calleeName, date: notification?.createdDate };
      store.dispatch(showSnackbar({ snackbarType: SnackbarType.CoachingTip, snackbarData}));
      // refresh call logs
      SaleforceService.getCallHistory();
    }  else if (notification?.notificationType === 'Misscall') {
      SaleforceService.getCallHistory();
    }
  });
}

  /**
   * Listens to the call list updates
   * @param connection the signalR connection object
   */
  static UpdateContactInfo = (connection: signalR.HubConnection) => {
    connection.on('UpdateContactInfo', (contact) => {
      const callingContact = store.getState()?.callingContact?.data;
      const contactId = callingContact?.contactId || callingContact?.id;
      console.log(LogTitle, "UpdateContactInfo: ", {contact, callingContact});

      if (callingContact && contactId === contact?.id) {
        store.dispatch(addCallingContact(contact));
      }
    });
  }

  /**
   * Listens to the RCcall Trascript updates
   * @param connection the signalR connection object
   */
  static RCCallTranscript = (connection: signalR.HubConnection) => {
    connection.on('RCTranscription', (transcript) => {
      console.log(LogTitle, "RCTranscription: ", {transcript});
      const notificationForCurrentCall = transcript.callRecordId === store.getState()?.callCentre?.currentActiveCallRecordId;

        if (!notificationForCurrentCall && transcript.segment) {
          var TRANSCRIPTMC = "rocketphone__TRANSCRIPTMC__c";
            SaleforceService.publishObject(transcript, TRANSCRIPTMC);
        }
    });
  }

  /**
   * Listens to recording completion
   */
  static recordingAdded = (connection: signalR.HubConnection) => {
    // Skip registering to this event if test flag is set
    // if (window.SIGNALR_DISABLE_RECORDING_EVENT) return;

    // connection.on('RecordingAdded', CallActivityService.onCallRecordingMessageReceived);
  }

  /**
   * Listens to transcription completion
   */
  static transcriptionAdded = (connection: signalR.HubConnection) => {
    // Skip registering to this event if test flag is set
    // if (window.SIGNALR_DISABLE_TRANSCRIPTION_EVENT) return;

    // connection.on('TranscriptionAdded', CallActivityService.onCallTranscriptMessageReceived);
  }

  /**
   * Listens to transcript manual bookmarks completion
   */
  static bookmarkTranscriptionsAdded = (connection: signalR.HubConnection) => {
    // Skip registering to this event if test flag is set
    // if (window.SIGNALR_DISABLE_MANUAL_BOOKMARK_EVENT) return;

    // connection.on('BookmarkTranscriptionsAdded', CallActivityService.onCallManualBookmarksMessageReceived);
  }

//   /**
//    * Listens to transcript NLP tagging completion
//    */
//   static nlpBookmarksAdded = (connection) => {
//     // Skip registering to this event if test flag is set
//     if (window.SIGNALR_DISABLE_NLP_BOOKMARK_EVENT) return;

//     connection.on('NLPBookmarksAdded', CallActivityService.onCallNlpBookmarksMessageReceived);
//   }
  
  /**
   * Listens to call status change
   */
  static updateCallStatusChange = (connection: signalR.HubConnection) => {
    connection.on('CallStatusChanged', (notification) => {
      console.log(LogTitle, "CallStatusChanged: ", {notification});
      const callInProgress = notification.status === CallActivityStatusEnum.inProgress && store.getState()?.callCentre?.callBarState !== CallBarState.IN_PROGRESS;
      const notificationForCurrentCall = notification.id === store.getState()?.callCentre?.currentActiveCallRecordId;

      if (callInProgress) {
        if (notificationForCurrentCall){
          const callDirection = TwilioVoiceService.getCallDirection(TwilioVoiceService.callConnection);
          if (callDirection === CallActivityDirectionEnum.outgoing) {
            clearInterval(TwilioVoiceService.streamConnectionTimerId);
            CallActivityService.resolveCallInProgressStatusChange(notification);
          }
        } else {
          if (notification?.medium === 'Sim') {
            TwilioVoiceService.updateUserAvailableStatus(notification.id, true);
          }
        }
      } else {
        const callsOnHoldData = store.getState()?.callState?.callsOnHold;

        if (notification.status === 'hangup' && callsOnHoldData[notification.id] && notificationForCurrentCall) {
          const isCallDispositionNotMandatory = store.getState()?.settings?.tenantSettings?.tenantCallSettings?.isCallDispositionNotMandatory;

          if (isCallDispositionNotMandatory) {
            store.dispatch(updateCallBarState(CallBarState.IDLE));
          } else {
            store.dispatch(updateCallBarState(CallBarState.POST_CALL));
          }

          TwilioVoiceService.updateUserAvailableStatus(null, false);
          store.dispatch(setIncomingCallIdle());
          store.dispatch(updateCurrentActiveCallRecordId(null));
          store.dispatch(setIsCurrentCallInHold(false));
        } else if (notification.status === 'failed') {
          const snackbarData = { message: notification.errorMessage};
          store.dispatch(showSnackbar({ snackbarType: SnackbarType.Error, snackbarData }));
        }
        //  else if (notification.status === 'no-answer') {
        //   if (!TwilioVoiceService.callConnection) {
        //     console.log(LogTitle, "CallStatusChanged: Incoming screen dismiss", {notification});
        //     store.dispatch(setIncomingCallIdle());
        //   }
        // }
      }
    });
  }

  /**
   * Listens to conference call status change
   */
  static updateConferenceCallStatusChange = (connection: signalR.HubConnection) => {
    connection.on('ConferenceStatusChanged', (notification) => {
        console.log(LogTitle, "ConferenceStatusChanged: ", {notification});
    //   CallHotTransferService.resolveConferenceEvents(notification);
    });
  }
  
//   /**
//    * Listens to daily user insight user status update
//    */
//   static dailyUserInsightUserStatusUpdate = (connection) => {
//     connection.on('DailyUserInsightUserStatusUpdate', (notification) => {
//       store.dispatch(updateUserStatusChange(notification));
//     });
//   }

//   /**
//    * Listens to daily user insight outgoing call insight update
//    */
//   static dailyUserInsightOutgoingCallInsightUpdate = (connection) => {
//     connection.on('DailyUserInsightOutboundCallUpdate', (notification) => {
//       store.dispatch(updateOutboundCallStat(notification));
//       store.dispatch(getCallStats());
//     });
//   }

//   /**
//    * Listens to daily user insight incoming call insight update
//    */
//   static dailyUserInsightIncommingCallInsightUpdate = (connection) => {
//     connection.on('DailyUserInsightInboundCallUpdate', (notification) => {
//       store.dispatch(updateInboundCallStat(notification));
//       store.dispatch(getCallStats());
//     });
//   }

//   static updateCallQueueWithCallAuditEvent = (connection: signalR.HubConnection) => {
//     connection.on('CallAuditEventAdded', (notification) => {
//     //   store.dispatch(receiveCallQueueUpdate(notification));
//     });
//   }

  static availabilityInquireEvent = (connection: signalR.HubConnection) => {
    connection.on('AvailabilityInquire', () => {
        console.log(LogTitle, "AvailabilityInquire: ");
        TwilioVoiceService.attemptReRegistration();
        this.sendActiveConnectionIds();
    });
  }

  /*
  Listen User Status changed
  */
  static updateUserStatusChange = (connection: signalR.HubConnection) => {
    connection.on('UserStatusUpdate', (notification) => {
      const userId = store.getState()?.auth?.user?.id;
      console.log(LogTitle, "UserStatusUpdate: ", { notification, userId });
      const userStatus = { id: notification?.userStatusId, timestamp: notification?.lastActiveTime };
      const available = notification?.available;

      if (userId && userId === notification?.userId) {
        store.dispatch(updateUserAvailabilityStatus({available, userStatus}));
      }
    });
  }

  static updateUserDisconnectMessage = (connection: signalR.HubConnection) => {
    connection.on('UserDisconnectMessage', (notification) => {
      console.log(LogTitle, "UserDisconnectMessage: ", { notification });
      // const userId = store.getState()?.auth?.user?.id;
      
      // if (notification?.userId === userId) {
      //   this.sendActiveConnectionIds();
      // }
    });
  }
}
